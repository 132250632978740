<template>
  <div :class="$style.container">
    <form :class="$style.card" @submit.prevent="submitForm">
      <Button @click="openNotesSidebar" :class="$style.notesButton"
        >Заметки менеджеров</Button
      >
      <h2 :class="$style.title">{{ editDealerLabel }} дилера</h2>
      <h4 :class="$style.subtitle">Имя: {{ manager.name }}</h4>
      <h4 :class="$style.subtitle">Email: {{ manager.email }}</h4>
      <div v-if="isBanned" :class="$style.bannedInfo">
        <span :class="$style.bannedStatus">Статус: заблокирован</span>
        <adw-button v-if="isAdminOrOwner" @click="unBanningDealer"
          >Разблокировать дилера</adw-button
        >
      </div>
      <Select
        v-model="form.type"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :options="$options.DEALERS_TYPES"
        :error="errors.type"
        placeholder="Тип бизнеса"
      />
      <Select
        v-model="form.managerAccountId"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :options="managers"
        placeholder="Менеджеры"
      />
      <Select
        v-model="form.coordinatorId"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :options="coordinators"
        placeholder="Координаторы"
      />
      <Select
        v-model="form.dealerCatalogId"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :options="catalogs"
        placeholder="Каталоги"
      />
      <Select
        v-model="form.pricePolicyId"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :options="pricesPolicy"
        placeholder="Ценновые политики"
      />
      <Input
        v-model="form.tgChannelId"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :error="errors.name"
        placeholder="ID telegram чата"
      />
      <Input
        v-model="form.paymentDelayDays"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :error="errors.paymentDelayDays"
        placeholder="Отсрочка платежа (дней)"
      />
      <Input
        v-model="form.commodityCredit"
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        :error="errors.commodityCredit"
        placeholder="Товарный кредит (руб)"
      />
      <Checkbox
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        v-model="form.ieCartDiscountIsAvailable"
      >
        Доступна скидка для корзины ИП
      </Checkbox>
      <Checkbox
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        v-model="form.llcCartDiscountIsAvailable"
      >
        Доступна скидка для корзины ООО
      </Checkbox>
      <Checkbox
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        v-model="form.rwCartDiscountIsAvailable"
      >
        Доступна скидка для корзины УС
      </Checkbox>
      <Checkbox
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        v-model="form.isSupplier"
      >
        Поставщик
      </Checkbox>
      <Checkbox
        :disabled="!isAdminOrOwner"
        :class="$style.input"
        v-model="form.isBonusProgramMember"
      >
        Участвует в Бонусной программе
      </Checkbox>
      <Checkbox disabled :class="$style.input" v-model="form.isNotifyByEmail">
        Получать уведомление о заказах на почту
      </Checkbox>

      <section :class="$style.section">
        <h3 :class="$style.title">
          Кол-во дней до автоблокировки пользователя
        </h3>
        <el-input-number
          v-model="form.daysToBan"
          :disabled="!isAdminOrOwner"
          :class="$style.input"
          type="number"
          :min="0"
          :placeholder="'0'"
        />
      </section>
      <div v-if="isAdminOrOwner" :class="$style.action">
        <Button :type="'submit'">Сохранить</Button>
        <Button @click="submitFormAndReturn()">Сохранить и вернуться</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
      <Loader :isLoading="isLoading" />
    </form>
    <DealerSidebar
      :isOpen="isOpenNotesSidebar"
      :dealerId="$route.params.id"
      :dealerName="manager.name"
      @toggle="isOpenNotesSidebar = $event"
    />
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import Input from '@/components/atoms/Input.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Select from '@/components/atoms/Select.vue'
import DealerSidebar from '@/components/sellers/organisms/DealerSidebar.vue'
import { DEALERS_TYPES } from '@/constants/dealers'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import delivery from '@/delivery'
import notifications from '@/mixins/notifications.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    Select,
    Button,
    Loader,
    Checkbox,
    Input,
    DealerSidebar,
  },
  DEALERS_TYPES,
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      isBanned: null,
      limit: 1000000,
      isLoading: false,
      error: '',
      isOpenNotesSidebar: false,
      form: {
        tgChannelId: '',
        type: null,
        managerAccountId: null,
        dealerCatalogId: null,
        coordinatorId: null,
        pricePolicyId: null,
        ieCartDiscountIsAvailable: false,
        llcCartDiscountIsAvailable: false,
        rwCartDiscountIsAvailable: false,
        isSupplier: false,
        isBonusProgramMember: null,
        daysToBan: 0,
        paymentDelayDays: null,
        commodityCredit: null,
        isNotifyByEmail: null,
      },
      errors: {
        type: null,
        paymentDelayDays: '',
        commodityCredit: '',
      },
      managers: [],
      coordinators: [],
      catalogs: [],
      manager: [],
      pricesPolicy: [],
    }
  },
  mounted() {
    this.getDealer()
    this.getManagers()
    this.getCatalogs()
    this.getCoordinators()
    this.getPricesPolicy()
  },
  computed: {
    ...mapGetters({
      isAdminOrOwner: 'isAdminOrOwner',
    }),
    editDealerLabel() {
      return this.isAdminOrOwner ? 'Просмотр' : 'Изменить'
    },
  },
  methods: {
    openNotesSidebar() {
      this.isOpenNotesSidebar = true
    },
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      if (!this.form.type) {
        isValid = false
        this.errors.type = 'выберите тип бизнеса'
      }

      if (
        this.form.paymentDelayDays &&
        !(
          Number(this.form.paymentDelayDays) &&
          Number.isInteger(Number(this.form.paymentDelayDays))
        )
      ) {
        isValid = false
        this.errors.paymentDelayDays =
          'Отсрочка в днях должна быть целым числом'
      }

      if (this.form.commodityCredit && !Number(this.form.commodityCredit)) {
        isValid = false
        this.errors.commodityCredit = 'Товарный кредит должен быть числом'
      }
      return isValid
    },
    async submitForm() {
      this.clearErros()
      this.isLoading = true
      if (this.isFormValid()) {
        this.form.name = this.manager.name
        this.form.email = this.manager.email
        this.form.managerAccountId = this.form.managerAccountId
          ? this.form.managerAccountId
          : 'default'
        this.form.dealerCatalogId = this.form.dealerCatalogId
          ? this.form.dealerCatalogId
          : 'default'
        this.form.coordinatorId = this.form.coordinatorId
          ? this.form.coordinatorId
          : 'default'
        this.form.pricePolicyId = this.form.pricePolicyId
          ? this.form.pricePolicyId
          : 'default'

        const { error } = await delivery.AddwineCore.DealersActions.update(
          this.$route.params.id,
          {
            ...this.form,
            paymentDelayDays: Number(this.form.paymentDelayDays),
            commodityCredit: Number(this.form.commodityCredit),
          },
        )

        this.isLoading = false

        if (error) {
          this.error = error
          return false
        }

        this.showNotification('Дилер успешно изменен', 'success')
        return true
      }
      this.isLoading = false
      return false
    },
    async submitFormAndReturn() {
      const isSuccessSubmit = await this.submitForm()
      if (isSuccessSubmit) {
        this.$router.go(-1)
      }
    },
    async getManagers() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList({
          page: 1,
          limit: this.limit,
          coordinators: false,
        })
      if (error) return
      this.managers = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.managers.unshift({ text: 'Менеджер по умолчанию', value: null })
      this.isLoading = false
    },
    async getCoordinators() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList({
          page: 1,
          limit: this.limit,
          coordinators: true,
        })
      if (error) return
      this.coordinators = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.coordinators.unshift({
        text: 'Координатор по умолчанию',
        value: null,
      })
      this.isLoading = false
    },
    async getCatalogs() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.CatalogsActions.getList({
          page: 1,
          limit: this.limit,
        })
      if (error) return
      this.catalogs = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.catalogs.unshift({ text: 'Каталог по умочанию', value: null })
      this.isLoading = false
    },
    async getDealer() {
      this.isLoader = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getById(this.$route.params.id)
      if (error) return
      this.manager = value
      this.form.type = value.type
      this.form.managerAccountId = value.managerAccountId
      this.form.dealerCatalogId = value.dealerCatalogId
      this.form.coordinatorId = value.coordinatorId
      this.form.pricePolicyId = value.pricePolicyId
      this.form.ieCartDiscountIsAvailable = value.ieCartDiscountIsAvailable
      this.form.llcCartDiscountIsAvailable = value.llcCartDiscountIsAvailable
      this.form.rwCartDiscountIsAvailable = value.rwCartDiscountIsAvailable
      this.form.isSupplier = value.isSupplier
      this.form.daysToBan = value.daysToBan
      this.form.isNotifyByEmail = value.isNotifyByEmail
      this.form.tgChannelId = value.tgChannelId
      this.form.isBonusProgramMember = value.isBonusProgramMember
      this.form.paymentDelayDays = value.paymentDelayDays
      this.form.commodityCredit = value.commodityCredit
      this.isLoader = false
      this.isBanned = value.isBanned
    },
    async getPricesPolicy() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.PricePolicyActions.getList({
          page: 1,
          limit: this.limit,
        })
      this.isLoading = false
      if (error) return
      this.pricesPolicy = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.pricesPolicy.unshift({
        text: 'Ценовая политика по умолчанию',
        value: null,
      })
    },
    async unBanningDealer() {
      const { value, error } = await delivery.AddwineCore.DealersActions.patch(
        this.$route.params.id,
        { isBanned: false, banWarningMessageSent: false },
      )

      if (error) {
        this.showNotification('Ошибка разблокировки дилера', 'error')
        return
      }

      this.isBanned = value.isBanned

      this.showNotification('Дилер успешно разблокирован', 'success')
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 78.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  flex: 1 1 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .subtitle {
    color: $tabel-th-color;
    margin: 1rem 0;
  }

  .notesButton {
    width: max-content;
    margin-bottom: 1rem;
  }

  .bannedInfo {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 1rem 0;

    .bannedStatus {
      color: $tabel-th-color;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    max-width: 38.5rem;
    margin: 0 auto;
    padding: 2rem;
    background: $white;
    .title,
    .input {
      margin-bottom: 1.5rem;
      color: $black-gray;
    }
    .input {
      &.agree {
        a {
          color: $dark-orange;
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      gap: 1rem;

      .error {
        @include errorInscription;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
